import { UnitTypes } from '@modules/teeth-diagram/models/unit-type.enum';

export const defaultDateFormat = 'MM/dd/yyyy';
export const defaultLanguageCode = 'en-US';
export const positiveIntegersOnlyRegExp = /^(?:[1-9]\d*|0)$/;
export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const defaultExpirationPeriodForReferral = 60; // In days
export const maxAlignerNumberValue = 99;

export const bridgeUnitType = null;

export const unitTypeSortOrder = [
	UnitTypes.Crown,
	UnitTypes.Crown_ThreeQuarters,
	UnitTypes.ImplantAbutment,
	UnitTypes.ScanBody,
	UnitTypes.Veneer,
	UnitTypes.Inlay,
	UnitTypes.Onlay,
	UnitTypes.Missing_edentulousSpace,
	UnitTypes.Missing_noSpace,
	UnitTypes.Detachable,
	UnitTypes.ImplantPosition,
	UnitTypes.SupportingTooth,
	UnitTypes.Clasp,
	UnitTypes.ToBeRemoved,
	UnitTypes.Regular,
	UnitTypes.CerecGuide,
	UnitTypes.ImplantBased,
	UnitTypes.VeneerOrLaminates,
	UnitTypes.Missing,
	bridgeUnitType,
	UnitTypes.EggshellCrown
];

export const createdByNameIteroTeam = 'iTero Team';
export const debounceDelay = 300;
export const appSettingsPath = '/assets/config/app.settings.json';

export const unitTypesWithoutDetails = [
	UnitTypes.Regular,
	UnitTypes.Detachable,
	UnitTypes.Missing_edentulousSpace,
	UnitTypes.Missing_noSpace,
	UnitTypes.Missing,
	UnitTypes.ToBeRemoved,
	UnitTypes.Clasp,
	UnitTypes.SupportingTooth,
	UnitTypes.EggshellCrown
];

export const customerSupportLink = 'https://itero.com/education-and-support/contact-us';
export const stayInRx = 'stayInRx';

export enum SessionType {
	Web = 'Web',
	Desktop = 'Desktop'
}
